@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "@blueprintjs/table/lib/css/table.css";
@import "@blueprintjs/core/lib/scss/variables";

$background-color: $light-gray2;
$content-background-color: $light-gray4;
$dark-background-color: $dark-gray1;
$dark-content-background-color: $pt-dark-app-background-color;

$intent-primary-text: $blue1;
$intent-success-text: $green1;
$intent-warning-text: $orange1;
$intent-danger-text: $red1;

$dark-intent-primary-text: $blue5;
$dark-intent-success-text: $green5;
$dark-intent-warning-text: $orange5;
$dark-intent-danger-text: $red5;

.bp5-overlay {
  background-color: rgba(0, 0, 0, 0.5);

}

.blue {
  color: $intent-primary-text;
}
.bp5-dark .blue {
  color: $dark-intent-primary-text;
}
.red {
  color: $intent-danger-text;
}
.bp5-dark .red {
  color: $dark-intent-danger-text;
}
.orange {
  color: $intent-warning-text;
}
.bp5-dark .orange {
  color: $dark-intent-warning-text;
}

.green {
  color: $intent-success-text;
}
.bp5-dark .green {
  color: $dark-intent-success-text;
}

.orange {
  color: $intent-warning-text;
}
.bp5-dark .orange {
  color: $dark-intent-warning-text;
}

.container {
  background-color: $background-color;
}
.bp5-dark .container {
  background-color: $dark-background-color;
}

.root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.container {
  flex-grow: 1;
  overflow: auto; // Add scrolling to container if content overflows
}
.bp5-dark .content {
  background-color: $dark-content-background-color;
}

.bp5-light .content {
  background-color: $content-background-color;
}
.content {
  padding: 20px;
  background-color: $content-background-color;
}
.dark_plotly_container {
  filter: invert(85%) hue-rotate(180deg);
}
// .light_plotly_container {
//   filter: invert(15%) hue-rotate(0deg);
// }

// if less than 600px
@media (max-width: 600px) {
  .content {
    padding-left: 0;
    padding-right: 0;
  }
}
